import React, { useRef } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import Button from "../ui-components/Button";
import { BookDemoBig } from "../ui-components/BookDemos";
import { StaticImage } from "gatsby-plugin-image";
import DemoPortrait from "../images/DemoPortrait.png";
import StraightCurves from "../images/Curves/Products/StraightCurves.svg";
import ClientsSection from "../components/ClientsSection";
import LandingTestimonialCTA from "../components/LandingTestimonialCTA";
import Product from "../ui-components/Product";
import { graphql, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { DeepNonNullable } from "ts-essentials";
import { ProductsDataQuery } from "../../graphqlTypes";
import styled from "styled-components";
import { TAILWIND_DEFAULTS } from "../utils/GlobalConstants";
import { mdxBody } from "@utils/mdxUtils";
import BookADemoModal from "@ui/Modals/BookADemoModal";

const ProductsDiv = styled.div`
  @media (min-width: ${TAILWIND_DEFAULTS.screens.lg}) {
    background-image: url(${StraightCurves});
    background-repeat: repeat-y;
    background-position: center;
  }
`;

const ProductPage = () => {
  const data = useStaticQuery<DeepNonNullable<ProductsDataQuery>>(graphql`
    query ProductsData {
      strapiProduct {
        pageTitle
        childStrapiProductTitle {
          childMdx {
            body
          }
        }
        features: feature {
          id
          title
          description
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        customer_case {
          companyLogo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 50, placeholder: BLURRED, layout: FIXED)
              }
            }
          }
          title
          slug
          excerpt1
          excerpt2
          companyPersonName
          companyPersonTitle
          companyName
        }
      }
    }
  `);

  const {
    pageTitle,
    features,
    customer_case,
    childStrapiProductTitle: title,
  } = data.strapiProduct;

  const {
    companyLogo: {
      localFile: { childImageSharp: companyLogoGatsby },
    },
  } = customer_case;

  const modalBookADemoRef = useRef();

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="div-block mt-10">
        <div className="flex flex-col items-start md:items-center text-center md:w-full md:p-20 bg-accuRankerPurple-900">
          <div
            id="title"
            className="text-left md:text-center text-white text-2xl md:text-5xl font-medium"
          >
            <MDXRenderer>{mdxBody(title)}</MDXRenderer>
          </div>
          <div className="mt-10">
            <Button
              color="orange"
              onClick={() => modalBookADemoRef.current.openModal()}
            >
              Book Demo
            </Button>
            <BookADemoModal ref={modalBookADemoRef} />
          </div>
        </div>

        {/* Features */}
        <ProductsDiv className="max-w-full mt-28 md:mt-0 md:px-4 xl:px-0 md:pt-0">
          {features.map((feature, index: number) => {
            const position = index % 2 === 0 ? "left" : "right";
            return (
              <div className="mb-20 last:mb-3">
                <Product
                  title={feature.title}
                  description={feature.description}
                  image={
                    feature.image.localFile.childImageSharp.gatsbyImageData
                  }
                  imagePosition={position}
                />
                {/* CTA Demo */}
                <div className="flex flex-row justify-center mx-auto mt-20">
                  <BookDemoBig
                    cta="Book Demo With Our Manager"
                    image={DemoPortrait}
                    jobTitle="Client Service Manager"
                    name="Victor Pan"
                    text="Get a free demo by one of our customer success managers"
                  />
                </div>
              </div>
            );
          })}
        </ProductsDiv>
        {/* Last Sections */}
        <div>
          <div className="hidden lg:block w-full text-center -mb-1">
            <StaticImage
              alt="curves"
              src="../images/Curves/ProductsBeforeClients.svg"
              style={{
                margin: "auto 0 -10px auto",
              }}
            />
          </div>
          <div className="max-w-full md:px-20 lg-break custom-faded-curves">
            <ClientsSection />
          </div>
          <div className="mx-10">
            <LandingTestimonialCTA {...(customer_case as any)} />
          </div>
          <div className="hidden lg:block w-full text-center">
            <StaticImage
              quality={99}
              alt="footer-curve"
              src="../images/Curves/FooterTouching.svg"
            />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default ProductPage;
