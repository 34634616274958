import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import classNames from "classnames";
import { ShadowForCurves } from "../styles/shared";

export type ProductPageProps = {
  imagePosition: "left" | "right";
  image: string | IGatsbyImageData;
  title: string;
  description: string;
};

const Product: React.FC<ProductPageProps> = ({
  imagePosition,
  image,
  title,
  description,
}) => {
  return (
    <div className="grid grid-cols-5 gap-10 lg:gap-44">
      <div
        className={classNames(
          "col-span-5 order-first mx-10 lg:mx-0 lg:col-span-3",
          {
            "md:order-first": imagePosition === "left",
            "md:order-last": imagePosition === "right",
          }
        )}
      >
        {typeof image === "string" ? (
          <img src={image} className="rounded-xl" alt="product-image" />
        ) : (
          <GatsbyImage
            className="rounded-xl"
            image={image}
            alt="product-image"
            style={{ ...ShadowForCurves }}
          />
        )}
      </div>
      <div className="col-span-5 mx-10 lg:mx-0 lg:col-span-2 m-auto">
        <div>
          <h2 className="text-accuRankerOrange-500 text-3xl font-normal font-sans">
            {title}
          </h2>
          <p className="text-white text-xl">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Product;
